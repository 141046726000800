<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/repair-orders">
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="维保班组">
            <el-input v-model.trim="filter.teamName" clearable></el-input>
          </vm-search>
          <vm-search label="维修人姓名">
            <el-input v-model.trim="filter.assigneeName" clearable></el-input>
          </vm-search>
          <vm-search label="维修人电话">
            <el-input v-model.trim="filter.assigneePhone" clearable></el-input>
          </vm-search>
          <vm-search label="工单编号">
            <el-input v-model.trim="filter.orderCode" clearable></el-input>
          </vm-search>
          <vm-search label="维修类型">
            <vm-dict-select v-model="filter.typeDesc" type="repairType"></vm-dict-select>
          </vm-search>

          <vm-search label="报修时间开始">
            <el-date-picker
              v-model="filter.createTimeFrom"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </vm-search>
          <vm-search label="报修时间结束">
            <el-date-picker
              v-model="filter.createTimeTo"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </vm-search>
          <vm-search label="使用单位">
            <el-input v-model.trim="filter.usingCompanyName" clearable></el-input>
          </vm-search>
          <vm-search label="楼盘">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="内部编号">
            <el-input v-model.trim="filter.assigneeName" clearable></el-input>
          </vm-search>
          <vm-search label="品牌">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>

          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="认领时间">
            <el-date-picker
              v-model="filter.asTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
          <vm-search label="是否认领超时">
            <el-select v-model="filter.assignedTimeout">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="到达时间">
            <el-date-picker
              v-model="filter.arTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
          <vm-search label="完成超时时间">
            <el-date-picker
              v-model="filter.ctoTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
          <vm-search label="是否完成超时">
            <el-select v-model="filter.completedTimeout">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="assigneeName" label="认领人姓名/电话">
        <template slot-scope="scope">
          {{scope.row.assigneeName}}/{{scope.row.assigneePhone}}
        </template>
      </el-table-column>
      <el-table-column prop="orderCode" label="工单编号"></el-table-column>
      <el-table-column prop="typeDesc" label="维修类型"></el-table-column>
      <el-table-column prop="createTime" label="报修时间"></el-table-column>
      <el-table-column prop="customerCompanyName" label="使用单位"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="内部编号"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.elevatorDetail.open(scope.row.elevatorId)">{{scope.row.regCode}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="品牌型号">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="认领时间">
        <template slot-scope="scope">
          <span v-if="scope.row.assignedTimeout">
            {{scope.row.assignedTime}}({{scope.row.assignedTimeoutLevel}}级超时)
          </span>
          <span v-else>{{scope.row.assignedTime}}(未超时)</span>
        </template>
      </el-table-column>
      <el-table-column prop="arrivedTime" label="到达时间"></el-table-column>
      <el-table-column prop="createTime" label="是否完成超时">
        <template slot-scope="scope">
          <span v-if="scope.row.completedTimeoutLevel">
            {{scope.row.completedTimeoutLevel}}级超时(超时时间{{scope.row.completedTimeoutTime}})
          </span>
          <span v-else>未超时(超时时间{{scope.row.completedTimeoutTime}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="modelDesc" label="设备型号"></el-table-column>
      <el-table-column prop="faultCode" label="故障代码/故障名称">
        <template slot-scope="scope">
          {{scope.row.faultCode}}/{{scope.row.faultName}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('进行中详情')" type="primary" @click="$refs.editPage.open(scope.row)">{{$l("common.detail", "详情")}}</el-button>
          <el-button v-if="$auth('进行中误报')" type="danger" @click="$refs.MisinformationEdit.open(scope.row.id)">{{$l("common.detail", "误报")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <misinformation-edit ref="MisinformationEdit" @save-success="getList(-1)"></misinformation-edit>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import EditPage from "./RepairDetailNew.vue";
  import MisinformationEdit from "@/views/maintenance/repair/reportOrder/MisinformationEdit";
  import moment from "moment";
  import {export2Excel} from "@/util";
  import elevatorDetail from "@/views/elevator/ElevatorDetail";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: { EditPage,MisinformationEdit,elevatorDetail},
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          orderStatus: "repairing",
          orderColumnName:"createTime",
          maintenanceType:"regulation",
          createTimeFrom:moment(moment().subtract(1, "months")).format("YYYY-MM-DD"+" 00:00:00"),
          companyId: loginUtil.getCompanyCode(),
        },

      };
    },

    mounted() {
      this.getList(1);
    },
    methods: {
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.createTimeFrom = this.filter.seTime[0];
          this.filter.createTimeTo = this.filter.seTime[1];
        }
        if(this.filter.asTime !=null) {
          this.filter.assignedTimeFrom = this.filter.asTime[0];
          this.filter.assignedTimeTo = this.filter.asTime[1];
        }
        if(this.filter.arTime !=null) {
          this.filter.arrivedTimeFrom = this.filter.arTime[0];
          this.filter.arrivedTimeTo = this.filter.arTime[1];
        }
        if(this.filter.ctoTime !=null) {
          this.filter.completedTimeoutTimeFrom = this.filter.ctoTime[0];
          this.filter.completedTimeoutTimeTo = this.filter.ctoTime[1];
        }
      },
      exportExcelData() {
        const title={
          teamName:"维保班组",
          teamLeaderName:"队长",
          teamLeaderPhone:"联系电话",
          assigneeName:"认领人姓名",
          assigneePhone:"电话",
          orderCode:"工单编号",
          orderStatusDesc:"工单状态",
          typeDesc:"维修类型",
          createTime:"报修时间",
          customerCompanyName:"使用单位",
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"内部编号",
          regCode:"注册代码",
          brandName:"品牌",
          modelName:"型号",
          assignedTimeoutLevel:"认领超时等级",
          assignedTime:"认领时间",
          arrivedTime:"到达时间",
          completedTimeoutLevel:"完成超时等级",
          completedTimeoutTime:"完成超时时间",
          modelDesc:"设备型号",
          faultCode:"故障代码",
          faultName:"故障名称",
        };
        this.$http.get("maintenance/repair-orders",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`维修工单-进行中-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },

      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.projectName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plan/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.projectName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
